/* Global styles */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

/* Styling for the multi-step form */
body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("bg.png"), -webkit-linear-gradient(bottom, #232864, #003160, #00354f, #00343c, #12322e);
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

::selection {
  color: #fff;
  background: #171d62;
}

.Main{

  background: white;
  padding: 25px;
  height: 600px;
  width: 390px;
}
/* Existing styles */

.form-container {
  width: 400px; /* Update width */
  height: 700px; /* Update height */
  padding: 40px;
  border-radius: 10px;
  background-color: #fff; /* Set background color to white */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}


.steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.step {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.step.active {
  background-color: #2196f3;
  color: #fff;
}

.form {
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.form-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type='text'],
input[type='email'],
input[type='tel'],
select,
button {
  width: calc(100% - 24px);
  padding: 12px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;

  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input[type='text']:focus,
input[type='email']:focus,
input[type='tel']:focus,
select:focus,
button:focus {
  outline: none;
}


/* Styling for file input */
input[type='file'] {
  width: calc(100% - 24px);
  padding: 12px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: block;
  color: transparent; /* Hide the text */
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}

input[type='file']::before {
  content: 'Upload CardID';
  display: inline-block;
  background: #2196f3;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  cursor: pointer;
}

input[type='file']:hover::before {
  background: #0d8bf2;
}

button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: #2196f3;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #0d8bf2;
}

/* Global styles */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

/* Mobile-first approach */
body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  background: url("bg.png"), -webkit-linear-gradient(bottom, #232864, #003160, #00354f, #00343c, #12322e);
}

/* Adjustments for smaller screens */
@media only screen and (max-width: 600px) {
  .Main {
    background: white;
    margin-left: 20px;
    padding: 15px;
    height: auto;
    width: calc(100% - 30px);
    max-width: 350px; /* Limit the maximum width */
  }
  
  .form-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: red;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  body {
    background: #232864; /* Change the background for smaller screens */
  }

  .form-container {
    width: 90%; /* Adjust width for smaller screens */
    height: auto; /* Allow height to be determined by content */
    padding: 20px; /* Decrease padding for smaller screens */
    border-radius: 0; /* Remove border radius */
    box-shadow: none; /* Remove box shadow */
    background-color: transparent; /* Remove background color */
  }

  /* Adjust styles for steps */
  .steps {
    display: flex;
    flex-wrap: wrap; /* Allow steps to wrap on smaller screens */
    justify-content: center;
    margin-bottom: 5px; /* Maintain bottom margin */
  }

  .step {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: inline-block;
    text-align: center;
    line-height: 5px;
    font-size: 5px;
    transition: background-color 0.3s ease;
    margin-bottom: 2px; /* Adjust spacing between steps */
  }

  .step.active {
    background-color: #2196f3;
    color: #fff;
  }

  /* Adjust form container for smaller screens */
  .form {
    padding: 10px; /* Adjust padding */
    border-radius: 0; /* Remove border radius */
    box-shadow: none; /* Remove box shadow */
    transition: transform 0.3s ease, opacity 0.3s ease;
    background-color: red;
  }

  .form-step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  select,
  button,
  input[type='file'] {
    width: 70%; /* Full width for smaller screens */
    padding: 12px;
    margin-bottom: 15px; /* Adjust spacing between inputs */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  input[type='text']:focus,
  input[type='email']:focus,
  input[type='tel']:focus,
  select:focus,
  button:focus {
    outline: none;
  }

  input[type='file'] {
    background-color: rgba(255, 255, 255, 0.9);
    color: transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  input[type='file']::before {
    content: 'Upload CardID';
    display: inline-block;
    background: #2196f3;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 20px;
    cursor: pointer;
  }

  input[type='file']:hover::before {
    background: #0d8bf2;
  }

  button {
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: #2196f3;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  button:hover {
    background-color: #0d8bf2;
  }
}
